@import "../../../../public/assets/scss/tokens.scss";

.feed-group-management-filter {
  display: flex;
  margin-top: $spacing-size-xl;
  &__button {
    width: 50px;
    height: 50px;
    margin-left: $spacing-size-xxxl !important;
  }
}
