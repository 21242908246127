@import "../../../public/assets/scss/tokens.scss";

.layout {
  display: flex;
  min-height: 100vh;

  &__content {
    padding: calc($spacing-size-xxl * 2);
    width: calc(100% - 320px);
    margin-bottom: calc($spacing-size-xxl * 2);
  }

  &__content--collapsed {
    width: calc(100% - 96px);
  }
}
