@import "../../../../../../public/assets/scss/tokens.scss";

.expanded-menu {
  padding-left: calc($spacing-size-xl * 4);
  &__items {
    padding-top: $spacing-size-xxl;
    .MuiCollapse-wrapperInner {
      display: grid;
      gap: $spacing-size-xxl;
    }
  }
  &__link {
    font-weight: $font-weight-sm;
    font-size: $font-size-sm;
    line-height: $spacing-size-xl;
    text-decoration: none !important;
    color: $dark !important;
    &--active {
      color: $primary !important;
      font-weight: $font-weight-sm;
    }
    &:hover {
      color: $primary !important;
      text-decoration: none !important;
    }
  }
}
