@import "../../../../../../public/assets/scss/tokens.scss";

.management-dashboard-content-card {
  &__item {
    display: flex;
    justify-content: space-between;
    border-radius: $border-radius-md;
    padding: $spacing-size-md $spacing-size-xxl;
    margin-top: $spacing-size-md;
  }
  &__item-first-child {
    margin-top: $spacing-size-none;
  }
  &__label {
    font-weight: $font-weight-sm;
    font-size: $font-size-sm;
    line-height: $spacing-size-xxl;
    color: $primary-white;
  }
  &__item--success {
    background: $primary;
  }
  &__item--warning {
    background: $warning;
  }
  &__item--danger {
    background: $danger;
  }
}
