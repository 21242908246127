@import "../../../../../public/assets/scss/tokens.scss";
@import "../../../../../public/assets/scss/variables.scss";

.feed-group-feed-detail-chart {
  position: relative;
  background-color: $table-light;
  padding: calc($spacing-size-xxl * 2) calc($spacing-size-lg * 2);
  border-radius: $border-radius-xll;
  margin-top: calc($spacing-size-xxl * 2);
  flex-shrink: 0;
  width: 100%;

  &__first-element {
    @extend .feed-group-feed-detail-chart;
    margin-right: calc($spacing-size-xxl * 4);
  }

  &__spinner {
    position: absolute;
    top: 25%;
    left: 50%;
    width: 45px;
    height: 45px;
  }

  &__chart-axis-names {
    font-weight: $font-weight-md;
    font-size: $font-size-lg;
    line-height: $line-height-base;
    color: $dark;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    margin: $spacing-size-none;

    &--x-axis {
      @extend .feed-group-feed-detail-chart__chart-axis-names;
      bottom: $spacing-size-xl;
    }

    &--y-axis {
      @extend .feed-group-feed-detail-chart__chart-axis-names;
      text-transform: capitalize;
      top: 50%;
      left: $spacing-size-md;
      transform: translate(0%, -50%);
      transform: rotate(270deg);
    }
  }
}
