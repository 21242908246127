@import "../../../../../public/assets/scss/tokens.scss";
@import "../../../../../public/assets/scss/variables.scss";

.management-dashboard-content-cards {
  &__title {
    font-weight: $font-weight-md;
    font-size: $font-size-lg;
    line-height: $spacing-size-xxxl;
    color: $primary;
  }
  &__card {
    background: $light-lighter;
    box-shadow: $shadow-card;
    border-radius: $border-radius-xl;
    padding: $spacing-size-xl calc($spacing-size-xxxl * 2);
    min-height: 360px;
  }
  &__card-body {
    display: flex;
    justify-content: space-between;
  }
  &__card-body-first-child {
    padding-bottom: $spacing-size-lg;
    border-bottom: 1px solid $dark-light;
  }
  &__card-body-last-child {
    padding-top: $spacing-size-lg;
  }
  &__items {
    width: 100%;
    margin-left: $spacing-size-xxxl;
  }
}
