@import "../../../public/assets/scss/tokens.scss";

.auth {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-image: url(../../../public/assets/images/homepage-bg.jpg);
  &__logo {
    display: flex;
    margin-left: auto;
  }
  &__header {
    font-family: $font-base;
    font-weight: $font-weight-lg;
    font-size: $font-size-xxl;
    line-height: calc($font-size-lg * 2);
    text-transform: uppercase;
    text-align: center;
    color: $primary-white;
    margin-top: calc($spacing-size-xxxl * 2);
    margin-bottom: $spacing-size-none;
  }
  &__helper {
    margin-left: $spacing-size-none !important;
  }
  &__navigation {
    color: $primary-white !important;
    text-decoration: none !important;
  }
  &__input {
    width: 100% !important;
    position: relative;
    padding-left: calc($spacing-size-lg * 2) !important;
  }
  &__icon {
    position: absolute;
    top: 20%;
    left: $spacing-size-none;
    color: $primary;
  }
  &__label {
    padding-left: calc($spacing-size-lg * 2) !important;
  }
  .MuiInputLabel-shrink {
    padding-left: calc($spacing-size-lg * 3) !important;
  }
  .spinner-container {
    width: 30px;
    height: 30px;
    background: transparent;
  }
  &__title {
    color: $dark;
    width: 170px;
    font-weight: $font-weight-lg;
    font-size: $font-size-xl;
    line-height: calc($spacing-size-xl * 2);
  }
  &__card {
    min-width: 500px;
    padding: $spacing-size-xxxl;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur($spacing-size-xl);
    border-radius: $spacing-size-xl;
  }
  &__button {
    width: 100%;
    text-transform: capitalize !important;
    border-radius: $border-radius-md !important;
    margin-top: $spacing-size-xxxl !important;
  }
}
