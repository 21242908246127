@import "../../../../public/assets/scss/tokens.scss";
@import "../../../../public/assets/scss/variables.scss";

.feed-group-feed-detail-content {
  &__charts-container {
    display: flex;
    overflow-y: scroll;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      width: $spacing-size-none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-radius: calc($border-radius-xll * 2);
    }
  }
}
