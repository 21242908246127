@import "../public/assets/scss/tokens.scss";

html,
body {
  font-family: $font-base !important;
  font-size: $font-size-base !important;
  line-height: $line-height-base !important;
  height: 100vh !important;
  background-color: #e5e5e5 !important;
}

a {
  text-decoration: none !important;
  color: rgba(0, 0, 0, 0.87);
}

button {
  font-family: $font-base !important;
  font-size: $font-size-base !important;
  line-height: $line-height-base !important;
}
