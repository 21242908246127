@import "../../../public/assets/scss/tokens.scss";

.production-sequence {
  &__tabs {
    margin-top: calc($spacing-size-xxl * 2);
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: $spacing-size-md;
  }
}
