@import "../../../../../../public/assets/scss/tokens.scss";
@import "../../../../../../public/assets/scss/variables.scss";

.feed-plan-sequence-table {
  margin-top: calc($spacing-size-xxl * 2);
  &__dragging-row {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr);
    background: $table-light;
  }
  &__warning {
    color: $danger;
    font-size: $font-size-m;
  }
  &__draggable-cell {
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }
  &__draggable-handle {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  &__drag-handle-icon {
    cursor: pointer;
    margin-right: $spacing-size-xl;
  }
  &__header {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__back-icon {
    cursor: pointer;
  }
  &__title {
    font-weight: $font-weight-sm;
    font-size: $font-size-md;
    line-height: $font-size-lg;
    cursor: pointer;
    color: $dark;
    padding-left: $spacing-size-lg;
  }
}
