@import "../../../../../../public/assets/scss/tokens.scss";

.growth-adjustment-conditions-table {
  margin-top: calc($spacing-size-xxl * 2);
  &__header {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__back-icon {
    cursor: pointer;
  }
  &__title {
    font-weight: $font-weight-sm;
    font-size: $font-size-md;
    line-height: $font-size-lg;
    cursor: pointer;
    color: $dark;
    padding-left: $spacing-size-lg;
  }
}
