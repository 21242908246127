@import "../../../../public/assets/scss/tokens.scss";
@import "../../../../public/assets/scss/variables.scss";

.sidebar {
  display: flex;
  position: relative;
  flex-direction: column;
  height: calc(100vh - 64px);
  width: 320px;
  overflow: auto;
  background-color: $primary-white;
  box-shadow: $shadow-sidebar;
  border-radius: $spacing-size-none $border-radius-xll $border-radius-xll $spacing-size-none;
  transition: width $transition-xxfast ease-in-out;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $primary;
    border-radius: calc($border-radius-xll * 2);
  }

  &__logo {
    margin: $spacing-size-xxxl $spacing-size-xxxl calc($spacing-size-xxxl * 2);
  }

  &__logo-min {
    width: 32px;
  }

  &--collapsed {
    width: 96px;
  }
}
