@import "../../../public/assets/scss/tokens.scss";
@import "../../../public/assets/scss/variables.scss";

.table-wrapper {
  &__warning {
    color: $danger;
    font-size: 18px;
  }
}

.table-container {
  border-radius: $border-radius-xl;
  background: $table-light;
  max-height: 65vh;
  &::-webkit-scrollbar {
    width: 15px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $primary;
    border-radius: calc($border-radius-xll * 2);
  }
  &__row {
    position: relative;
    height: 80px;
  }
  &__spinner {
    position: absolute;
    top: 25%;
    left: 50%;
    width: 45px;
    height: 45px;
  }
  &__empty-rows {
    display: flex;
    align-items: center;
    font-family: $font-base;
  }
  &__empty-icon {
    font-size: $font-size-xl !important;
  }
}
