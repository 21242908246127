@import "../../../../../../public/assets/scss/tokens.scss";

.feed-target {
  display: grid;
  align-items: center;
  grid-template-columns: 100px 250px 1fr;
  gap: $spacing-size-xl;
  margin: $spacing-size-lg $spacing-size-none;
  &__override {
    color: $dark-dark;
  }
}
