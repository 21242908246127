@import "../../../../../public/assets/scss/tokens.scss";

.responsive-dialog {
  &__title {
    text-align: left;
    font-size: $font-size-lg;
  }
  &__button-update {
    color: $primary !important;
  }
}
