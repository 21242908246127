@import "../../../../public/assets/scss/tokens.scss";

.breadcrumbs {
  .MuiBreadcrumbs-ol {
    .MuiBreadcrumbs-li {
      a {
        font-size: $font-size-lg;
        font-weight: $font-weight-md;
        display: flex;
        align-items: center;
      }
      &:first-child {
        a {
          color: $primary;
        }
      }
      &:not(:first-child) {
        a {
          color: $dark-light;
        }
      }
    }
  }

  &__item {
    &:hover {
      text-decoration: none;
    }
  }
}
