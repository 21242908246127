@import "../../../../../../public/assets/scss/responsive-dialog.scss";

.responsive-dialog {
  &__orders {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 50px;
  }
  &__order {
    display: flex;
    flex-direction: column;
  }
  &__order-property {
    font-weight: $font-weight-sm;
    font-size: $font-size-md;
    line-height: $spacing-size-xxl;
    color: $dark;
    margin-bottom: $spacing-size-none;

    &--no-space {
      margin: $spacing-size-none;
    }

    a {
      &:hover {
        color: $info;
      }
    }
  }

  &__order-status {
    display: flex;
    align-items: center;
    gap: $spacing-size-lg;
    margin-top: $spacing-size-xl;
  }

  &__order-icon {
    cursor: pointer;
  }
}
