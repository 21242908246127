@import "tokens.scss";

.responsive-dialog {
  &__text,
  &__title {
    text-align: center;
  }

  &__text-bold {
    font-weight: $font-weight-lg;
  }

  &__import-button {
    margin: 0 $spacing-size-xxxl $spacing-size-lg !important;
  }
}
