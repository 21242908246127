@import "../../../../../public/assets/scss/tokens.scss";
@import "../../../../../public/assets/scss/variables.scss";

.management-dashboard-content-issues-log {
  &__card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $light-lighter;
    box-shadow: $shadow-card;
    border-radius: $border-radius-xl;
    padding: $spacing-size-xl calc($spacing-size-xxxl * 2);
    min-height: 316px;
  }
  &__card--logs {
    align-items: unset;
    font-size: $font-size-md;
    color: $dark-light;
    font-weight: $font-weight-lg;
  }
  &__log {
    margin: 10px 0;
  }
  &__icon {
    font-size: calc($font-size-xl * 2) !important;
    color: $primary;
  }
  &__title {
    font-weight: $font-weight-md;
    margin-top: calc($spacing-size-xl * 2);
    font-size: $font-size-lg;
    line-height: $spacing-size-xxxl;
    color: $primary;
  }
  &__description {
    margin-top: $spacing-size-sm;
    font-weight: $font-weight-sm;
    font-size: $font-size-md;
    line-height: $spacing-size-xxl;
    color: $dark-light;
  }
}
