@import "../../../../../public/assets/scss/tokens.scss";

.toggle-button {
  display: flex;
  position: absolute;
  bottom: calc($spacing-size-xxxl * 2);
  right: $spacing-size-none;
  background-color: $primary;
  padding: $spacing-size-lg;
  border-radius: $border-radius-xl $spacing-size-none $spacing-size-none $border-radius-xl;
  cursor: pointer;

  &__icon {
    color: $primary-white;
    transform: rotateY(180deg);
  }

  &--collapsed {
    background-color: $light;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100px;
    border-radius: $border-radius-none;
    .toggle-button__icon {
      transform: rotateY(0deg);
    }
  }
}
