@import "../../../public/assets/scss/tokens.scss";

.with-image-bg {
  background: url("../../../public/assets/images/homepage-bg.jpg") no-repeat center center fixed;
  background-size: cover;
}

.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  width: 100%;
  height: 100%;

  &__links {
    list-style: none;
    margin: $spacing-size-none;
    padding: $spacing-size-none;
  }

  &__link {
    display: flex;
    width: 630px;
    height: 70px;
    cursor: pointer;
    margin-bottom: $spacing-size-xxl;
    align-items: center;
    justify-content: center;
    background-color: rgba($light-lighter, 0.8);
    border-radius: $border-radius-xl;
    text-decoration: none !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    font-size: $font-size-lg;
    color: $dark;
    transition: background-color $transition-fast linear;

    &:hover {
      background-color: $primary-white;
      text-decoration: none !important;
      color: $dark !important;
    }
  }

  &__icon {
    margin-right: $spacing-size-lg;
    width: 32px;
    height: 32px;
    color: $primary;
  }

  &__title {
    color: $primary-white;
    font-size: $font-size-xxl;
    text-transform: uppercase;
  }
}
