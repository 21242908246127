@import "../../../../public/assets/scss/tokens.scss";
@import "../../../../public/assets/scss/variables.scss";

.weekly-snapshot-table {
  margin-top: $spacing-size-xxl;
  &__settings {
    display: flex;
    align-items: center;
  }
}

.weekly-snapshot-table--management-dashboard {
  margin-top: $spacing-size-none;
  .table-container {
    box-shadow: $shadow-card;
    border-radius: $border-radius-xl;
    background: $light-lighter;
    min-height: 316px;
    thead {
      th {
        &:first-child {
          color: $primary;
        }
        color: $dark-light;
        background: $light-lighter;
        font-weight: $font-weight-lg;
      }
    }
    tbody {
      tr {
        th {
          background: $light-lighter;
          &:first-child {
            color: $primary;
          }
        }
        td {
          background: $light-lighter;
        }
      }
    }
  }
}
