@import "../../../../../public/assets/scss/tokens.scss";

.sidebar__link {
  display: flex;
  align-items: center;
  width: 100%;
  padding: $spacing-size-lg $spacing-size-xxxl;
  text-decoration: none !important;
  color: $primary-black;

  &__icon {
    width: 30px;
    height: 30px;
    color: $primary;
  }

  &__label {
    font-weight: $font-weight-sm;
    font-size: $font-size-m;
    line-height: $spacing-size-xl;
    color: $dark;
    padding-left: $spacing-size-xl;
    white-space: nowrap;
  }

  &__container {
    display: flex;
    align-items: center;
  }

  &--active {
    background-color: $primary;
    .sidebar__link__label {
      color: $primary-white;
    }
    .sidebar__link__icon {
      color: $primary-white;
    }
  }

  &--hide {
    display: none;
  }
}
