@import "../../../../public/assets/scss/tokens.scss";

.modified {
  font-weight: $font-weight-lg !important;
  font-style: italic;
}

.label {
  left: calc($spacing-size-xxs * 7) !important;
  top: -$spacing-size-sm !important;
}
