@import "../../../../public/assets/scss/tokens.scss";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__actions {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: $spacing-size-xxxl;
  }

  &__button {
    color: $primary;
  }

  &__link {
    display: flex;
    text-decoration: none;
    color: $primary;
  }
}
