@import "../../../../public/assets/scss/tokens.scss";

.footer {
  position: fixed;
  display: flex;
  background-image: url("../../../../public/assets/images/footer.png");
  bottom: -$spacing-size-xxs;
  left: 50%;
  width: 542px;
  height: 94px;
  align-items: center;
  justify-content: center;
  margin-left: -271px;
  animation: $transition-med ease-out $transition-none 1 slideUp;
  z-index: $z-index-xs;

  &__actions {
    display: flex;
    position: relative;
    bottom: -$spacing-size-sm;
    gap: calc($spacing-size-xl * 4);
  }

  &__button {
    background-color: $primary !important;
    color: $primary-white !important;

    &:hover {
      background-color: darken($primary, 15%) !important;
    }

    &:disabled {
      background-color: $light-base !important;
      pointer-events: none;
    }
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
