@import "../../../../../public/assets/scss/tokens.scss";

.feed-group-feed-detail-table {
  margin-top: calc($spacing-size-xxl * 2);

  &__feed-plan-summary {
    color: $info !important;
    cursor: pointer;
  }
}
