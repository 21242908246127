@import "../../../../../public/assets/scss/tokens.scss";

.table-cell {
  &__done-icon {
    color: $success;
  }
  &__block-icon {
    color: $danger;
  }
}
