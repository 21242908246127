@import "../../../../../public/assets/scss/tokens.scss";

.feed-forecast-filter {
  display: flex;
  &__button {
    width: 50px;
    height: 50px;
    margin-left: $spacing-size-xxxl !important;
  }
}
