@import "../../../../../public/assets/scss/tokens.scss";

.feed-plan-summary-table {
  margin-top: calc($spacing-size-xxl * 2);
  &__header {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
  &__label {
    font-weight: $font-weight-lg;
  }
}
