@import "../../../../../../public/assets/scss/tokens.scss";

.menu-icons {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax($spacing-size-xxl, 1fr));
  gap: $spacing-size-xxxl;
  padding: $spacing-size-xl calc($spacing-size-xxl * 3);
  &__icon {
    color: $primary;
  }
}
