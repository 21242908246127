@import "../../../../public/assets/scss/tokens.scss";

.system-setup-welcome {
  margin-top: calc($spacing-size-xxxl * 2);
  &__reset {
    display: flex !important;
    margin-left: auto !important;
  }
  &__header {
    font-weight: $font-weight-md;
    font-size: $font-size-lg;
    line-height: $spacing-size-xxl;
    color: $primary;
    text-align: center;
  }
  &__body {
    margin-top: calc($spacing-size-lg * 2);
    background: $light-light;
    border-radius: $border-radius-xl;
  }
  &__rows {
    cursor: pointer;
    margin-top: $spacing-size-none !important;
    margin-left: $spacing-size-none !important;
    width: 100% !important;
    &:hover {
      background: $light-lighter;
      border-radius: $border-radius-xl;
    }
  }
  &__key {
    font-weight: $font-weight-sm;
    font-size: $font-size-md;
    line-height: $spacing-size-xxl;
    color: $dark;
    text-align: center;
    padding: $spacing-size-lg $spacing-size-none;
  }
  &__value {
    font-weight: $font-weight-sm;
    font-size: $font-size-md;
    line-height: $spacing-size-xxl;
    color: $primary;
    text-align: center;
    padding: $spacing-size-lg $spacing-size-none;
  }
}
